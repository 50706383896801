import React from "react";
import "./App.css";

import useWeb3Modal from "./hooks/useWeb3Modal";
import { Web3Context } from "./hooks/useWeb3Context";
import { Web3Provider } from "@ethersproject/providers";
import WalletButton from "./ConnectWallet";

import { Devins__factory } from "./generated";
import { BigNumber, ethers } from "ethers";

import { CONTRACT_ADDRESS } from "./constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [pro, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();

  const [count, setCount] = React.useState(0);
  const [account, setAccount] = React.useState<string>("");
  const [provider, setProvider] = React.useState<Web3Provider>();

  const mint = async () => {
    // check if wallet
    if (!provider || !ethers.utils.isAddress(account)) {
      toast(`Wallet Not Connected`, {
        type: "error",
      });
      return;
    }
    const signer = provider.getSigner();
    if (!ethers.utils.isAddress(account)) return;
    const devin = Devins__factory.connect(CONTRACT_ADDRESS, signer);

    // claim
    try {
      const supply = await devin.totalSupply();
      // const price = BigNumber.from(parseUnits("0.3", "wei"));
      let minter;
      console.log("supply", supply.toString());
      if (BigNumber.from(supply).gte(BigNumber.from(48))) {
        minter = await devin.mint(count, {
          value: ethers.utils.parseUnits((0.03 * count).toString()),
        });
      } else {
        minter = await devin.mint(count, {
          value: ethers.utils.parseUnits((0 * count).toString()),
        });
      }
      await minter.wait(1);
      toast(`Minted ${count} Devins!`, {
        type: "success",
      });
    } catch (e: any) {
      console.log(e.message);
      toast(`${e.message}`, {
        type: "error",
      });
    }
  };

  return (
    <Web3Context.Provider
      value={{ account, setAccount, provider, setProvider }}
    >
      <ToastContainer />
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            backgroundColor: "#3498db",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              fontFamily: "Fredoka",
              fontSize: "100px",
              fontWeight: "700",
              color: "yellow",
            }}
          >
            Devins
          </div>
          <div>
            <img
              src="/assets/devins.gif"
              style={{ height: "240px" }}
              alt="devin"
            />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginBottom: "50px",
              }}
            >
              <button
                onClick={() => {
                  if (count > 0) {
                    setCount(count - 1);
                  }
                }}
                className={"button6"}
                style={{ backgroundColor: "Coral" }}
              >
                -
              </button>
              <div
                style={{
                  fontFamily: "Fredoka",
                  fontSize: "60px",
                  fontWeight: "700",
                  color: "yellow",
                }}
              >
                {count}
              </div>
              <button
                onClick={() => {
                  if (count < 15) {
                    setCount(count + 1);
                  }
                }}
                className={"button6"}
                style={{ backgroundColor: "Coral" }}
              >
                +
              </button>
            </div>
            <button
              onClick={() => {
                mint();
              }}
              disabled={account === ""}
              className={"button6"}
            >
              Mint
            </button>
            <WalletButton
              provider={pro}
              loadWeb3Modal={loadWeb3Modal}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 20px",
          }}
        >
          <div>Doodle Kevins 2022</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "30%",
            }}
          >
            <a
              href="https://twitter.com/DoodleKevins"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
            <a
              href="https://opensea.io/collection/devins-official"
              target="_blank"
              rel="noreferrer"
            >
              Opensea
            </a>
            <a
              href="https://etherscan.io/address/0xC082A9Ec90BEa20E01E1B884E9f667412802Add0"
              target="_blank"
              rel="noreferrer"
            >
              Etherscan
            </a>
          </div>
        </div>
      </div>
    </Web3Context.Provider>
  );
}

export default App;
