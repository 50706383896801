const TEST = false;

//// VERSION 2 ALPHA CAPITAL ////
export const CONTRACT_ADDRESS = TEST
  ? "0xC082A9Ec90BEa20E01E1B884E9f667412802Add0"
  : "0xC082A9Ec90BEa20E01E1B884E9f667412802Add0";
export const STAKING_CONTRACT_ADDRESS = TEST
  ? "0xCD06AAD8BF4AcA972a4d1c8E3145b16912769a15"
  : "0xE940ea6d77BCC223a43bB41AcE433CbFD39E3966";
export const LP_ADDRESS = "0xF2061Be4559b2356788C5c9Eeb0773F31bA427B9";

export const TREASURY_WALLET = "0xf914BbDFf49Fd4ba1A1cBFC740E0d60a8674f438";
export const MARKETING_WALLET = "0xfAb3595834A856193A6b41B51fd40B9ffF551ADA";
export const ADMIN_WALLETS = [TREASURY_WALLET, MARKETING_WALLET];

export const WALLET_MAP = { yieldFarming: "" };

//// LEGACY /////
export const LEGACY_CONTRACT_ADDRESS =
  "0x5B4e9a810321E168989802474f689269EC442681";
export const LEGACY_LP_ADDRESS = "0x045803b337e55b3a377db7b3523f21c334a8285b";

export const LEGACY_TREASURY_WALLET =
  "0x92eFade986f163fD1bA42A07AD640A0aD42bB41F";
export const LEGACY_MARKETING_WALLET =
  "0xED5B3ABb2c257A19daBEC1ecC745a6fCb0c5495E";
export const LEGACY_ADMIN_WALLETS = [
  LEGACY_TREASURY_WALLET,
  LEGACY_MARKETING_WALLET,
];

export const LEGACY_WALLET_MAP = {
  privateSale: "0x80b78919d5E5981C35E9De56899A31C14e7272A9",
  trading: "0x8398820561051678898cdCCD3a8cb29BAe7EBb1B",
  yieldFarming: "0x19Cd09a1438b8f2363c2de0cD42DE8e9c6ae2ab4",
};

//// CONSTANT ////

export const TELEGRAM = "https://t.me/AlphaCap_EntryPortal";
export const TWITTER = "https://twitter.com/alphacap_io";
export const MEDIUM = "https://medium.com/@alphacap";
export const DEXTOOLS = `https://www.dextools.io/app/ether/pair-explorer/${LP_ADDRESS}`;
export const DISCORD = `https://alpha-cap.io/`;

export const UNISWAP_BUY = `https://app.uniswap.org/#/swap?outputCurrency=${CONTRACT_ADDRESS}`;
export const UNISWAP_ADD_LIQ = `https://app.uniswap.org/#/add/v2/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2/${CONTRACT_ADDRESS}`;

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDg6VMnsR6tFa59XpI118BDn7LNNWvJUUI",
  authDomain: "abc-dao.firebaseapp.com",
  projectId: "abc-dao",
  storageBucket: "abc-dao.appspot.com",
  messagingSenderId: "399664803237",
  appId: "1:399664803237:web:66aa3d1f1cfe03242f0c4c",
  measurementId: "G-EE1MG0J5KF",
};

export type WalletKey = keyof typeof WALLET_MAP;
export const YIELD_FARMING_WALLETS = [
  WALLET_MAP.yieldFarming,
  "0x991ca5c0bb3E4c28Ad661798894942a04C659542",
];
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const LOCK_HASH =
  "0x797c951d5bab7d97436e6b46db93b2f93f73fab00fa803202d4465ecf3b72f38";
