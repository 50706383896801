import React from "react";
import { Web3Context } from "./hooks/useWeb3Context";

const WalletButton = ({
  provider,
  loadWeb3Modal,
  logoutOfWeb3Modal,
}: {
  provider: any;
  loadWeb3Modal: any;
  logoutOfWeb3Modal: any;
}) => {
  const { account, setAccount, setProvider } = React.useContext(Web3Context);
  const [rendered, setRendered] = React.useState("");

  React.useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }
        setProvider(provider);

        // Load the user's accounts.
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);

        // Resolve the ENS name for the first account.
        // const name = await provider.lookupAddress(accounts[0]);
        const name = null;
        // Render either the ENS name or the shortened account address.
        if (name) {
          setRendered(name);
        } else {
          console.log(account);
          setRendered(account.substring(0, 6) + "..." + account.substring(36));
        }
      } catch (err) {
        setAccount("");
        setRendered("");
        console.error(err);
      }
    }
    fetchAccount();
  }, [account, provider, setAccount, setRendered, setProvider]);

  return (
    <button
      onClick={() => {
        if (!provider) {
          loadWeb3Modal();
        } else {
          logoutOfWeb3Modal();
        }
      }}
      style={{ backgroundColor: "#1abc9c" }}
      className={"button6"}
    >
      {rendered === "" && "Connect Wallet"}
      {rendered !== "" && rendered}
    </button>
  );
};

export default WalletButton;
